(function($) {
  $(document).ready(function() {
    if ($.validate) {
      $.validate({
        lang: "en",
        modules: "toggleDisabled",
        disabledFormFilter: "form.add-webpage-form",
        showErrorDialogs: false
      });

      $('form.add-webpage-form input[name="title"').restrictLength(
        $("#title-text-counter .maxlength")
      );
      $('form.add-webpage-form textarea[name="description"').restrictLength(
        $("#description-text-counter .maxlength")
      );
    }

    $('form.add-webpage-form select[name="category_id"').change(function(e) {
      var selectedCategoryId = this.value;
      if (categoriesDependencies[selectedCategoryId]) {
        var subcategories = JSON.parse(
          categoriesDependencies[selectedCategoryId]
        );
        redrawSubcategoriesList(subcategories);
      }
    });
  });
})(jQuery);

function redrawSubcategoriesList(subcategories) {
  $('form.add-webpage-form select[name="subcategory_id"]').empty();
  for (var key in subcategories) {
    if (subcategories.hasOwnProperty(key)) {
      $("<option/>", {
        value: key,
        text: subcategories[key]
      }).appendTo($('form.add-webpage-form select[name="subcategory_id"]'));
    }
  }
}
